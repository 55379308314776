/* eslint-disable no-restricted-globals */
import { useState, useRef } from "react";
import DataTable from "../Table/Datatable";
import { format as dateFormat } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main";
import "./reports.scss";
import { INIT_FILTER_VALUE, filterDate, filterEmploye, filterStatsDate, filterStatsPartner } from "./filters";
import { Modal, Box, Typography, MenuItem } from "@material-ui/core";
import { FormControl, InputLabel, Select, Checkbox, ListItemText, ListSubheader, TextField, InputAdornment, Button  } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from 'react-router-dom';
import Chip from "@mui/material/Chip";

import { useSnackbar } from "notistack";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Select as UiSelect } from "../../../ui";
import useAdminUser from "../../../data/useAdminUser";

import useYaRequest from "../../../data/useYaRequest";

import { regionsData } from "./geolist.js";

const campaignStyles = {
  height: "100vh",
  overflowY: "scroll",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const staffStyles = {
  height: "160px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};



const StaffSelect = ({ el, field, label, arrStaff, updateField }) => {
  const [searchStaff, setSearchStaff] = useState('');
  const filteredStaff = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaff.toLowerCase())
  );

  return (
    <Box sx={{ width: "230px", textAlign: "start", padding: "2px 0", height: 30 }}>
      <FormControl fullWidth size="small">
        <InputLabel
          id={`${field}-select-label`}
          sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
        >
          {label}
        </InputLabel>
        <Select
          onOpen={() => setTimeout(() => document.getElementById(`search-input-${field}`).focus(), 100)}
          onChange={(e) => updateField(el.id, field, e.target.value === "Нет" ? null : e.target.value)}
          defaultValue={el[field] || "Нет"}
          style={{
            height: 32,
            margin: "0 10px",
            background: "#FFFFFF",
            borderColor: "transparent",
          }}
          sx={{
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          }}
          label={label}
          id={`${field}-select`}
          onClose={() => setTimeout(() => setSearchStaff(""), 300)}
        >
          <ListSubheader>
            <TextField
              id={`search-input-${field}`}
              size="small"
              autoFocus
              placeholder="Поиск"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchStaff(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  setTimeout(() => document.getElementById(`search-input-${field}`).focus(), 100)
                }
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem value="Нет">Нет</MenuItem>
          {filteredStaff.map((staff) => (
            <MenuItem key={staff.id} value={staff.id}>
              {staff.full_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const ListOfGoals = ({ goalsListOptions, filteredGoals, setSearchGoals, setSearchStaffAccount, handleCloseListGoals, defaultGoals }) => {
  const [listGoals, setListGoals] = useState([]);
  const [openItems, setOpenItems] = useState({});
  const [needUpdateGoals, setNeedUpdateGoals] = useState(false);

  useEffect(() => {
    setListGoals(defaultGoals);

    const newOpenItems = {};
    defaultGoals.forEach((goal) => {
      const goalId = goal.split('-')[0];
      newOpenItems[goalId] = true;
    });
    setOpenItems(newOpenItems);
  }, [defaultGoals]);

  useEffect(() => {
    const hasChanged = JSON.stringify(listGoals) !== JSON.stringify(defaultGoals);
    setNeedUpdateGoals(hasChanged);
  }, [listGoals, defaultGoals]);

  const handleToggleOpen = (id) => {
    setOpenItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleChangeListGoals = (event) => {
    const value = event.target.value;
    setListGoals(typeof value === "string" ? value.split(",") : value);
  };

  const handleCloseSelect = () => {
    setTimeout(() => setSearchStaffAccount(""), 300);

    if (!needUpdateGoals) return;

    handleCloseListGoals(listGoals);
    setNeedUpdateGoals(false);
  };

  const handleSelectChild = (goalId, child) => {
    const childValue = `${goalId}-${child}`;
    if (listGoals.includes(childValue)) {
      setListGoals(listGoals.filter(item => item !== childValue));
    } else {
      setListGoals(listGoals.filter(item => !item.startsWith(goalId)).concat(childValue));
    }
  };

  return (
    <Box sx={{ width: 200, textAlign: "start", padding: "2px 0", height: 30 }}>
      <FormControl fullWidth size="small">
        <InputLabel
          id="demo-select-small-label"
          sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
        >
          Список целей
        </InputLabel>
        <Select
          onOpen={() => setTimeout(() => document.getElementById("search-input-goals").focus(), 100)}
          style={{
            margin: "0 10px",
            fontSize: 14,
            height: 32,
            background: "#FFFFFF",
            borderColor: "transparent",
          }}
          sx={{
            "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          }}
          placeholder="Список целей"
          multiple
          value={listGoals || []}
          renderValue={(selected) => selected.join(', ')}
          onChange={handleChangeListGoals}
          onClose={handleCloseSelect}
        >
          <ListSubheader>
            <TextField
              id="search-input-goals"
              size="small"
              autoFocus
              placeholder="Поиск"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchGoals(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  setTimeout(() => document.getElementById("search-input-goals").focus(), 100);
                }
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {filteredGoals.map((goal) => {
            const goalIndex = goalsListOptions.findIndex((e) => e.goal_id === goal.goal_id);
            const isStandaloneGoal = ["Лиды(Calltracking)", "Сделки(Calltracking)"].includes(goal.name) && [1, 2].includes(goal.goal_id);
            
            return (
              <div key={goal.goal_id}>
                {isStandaloneGoal ? (
                  <MenuItem
                    style={{ display: "flex", gap: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectChild(goal.goal_id, "visits");
                  }}>
                    <Checkbox checked={listGoals.includes(`${goal.goal_id}-visits`)} />
                    <ListItemText primary={`${goal.name}`} />
                  </MenuItem>
                ) : (
                  <>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation();
                      handleToggleOpen(goal.goal_id);
                    }}>
                      <button
                        style={{
                          background: "none",
                          cursor: "pointer",
                          color: "#007bff",
                          marginRight: "10px",
                          fontSize: 20,
                          fontWeight: 500,
                          width: 20,
                          height: 20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {openItems[goal.goal_id] ? "-" : "+"}
                      </button>
                      <ListItemText primary={`${goalIndex + 1} - ${goal.name} - ${goal.goal_id}`} />
                    </MenuItem>
                    {openItems[goal.goal_id] && (
                      <>
                        <MenuItem style={{ display: "flex", gap: 10, marginLeft: 25 }} onClick={(e) => {
                          e.stopPropagation();
                          handleSelectChild(goal.goal_id, 'visits');
                        }}>
                          <Checkbox checked={listGoals.includes(`${goal.goal_id}-visits`)} />
                          <ListItemText primary="Целевые визиты" />
                        </MenuItem>
                        <MenuItem style={{ display: "flex", gap: 10, marginLeft: 25 }} onClick={(e) => {
                          e.stopPropagation();
                          handleSelectChild(goal.goal_id, 'users');
                        }}>
                          <Checkbox checked={listGoals.includes(`${goal.goal_id}-users`)} />
                          <ListItemText primary="Достижения целей" />
                        </MenuItem>
                      </>
                    )}
                  </>
                )}
              </div>
            );
          })}
          {filteredGoals.length === 0 && (
            <MenuItem disabled>
              <ListItemText style={{ fontSize: 14 }} primary="Нет доступных целей" />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};





const RegionSelect = ({ defaultValue, regions, handleChange }) => {
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [openRegions, setOpenRegions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [needUpdateRegions, setNeedUpdateRegions] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      const defaultRegionIds = defaultValue.split(",").map(item => {
        const [id, level] = item.split("-");
        return {
          id: id ? id.trim() : null,
          level: level ? parseInt(level.trim(), 10) : null
        };
      }).filter(item => item.id && item.level !== null);
  
      setSelectedRegions(defaultRegionIds.map(item => item.id));
    }
  }, [defaultValue]);

  const toggleRegion = (regionId, isIndeterminate) => {
    if (isIndeterminate) return;

    setSelectedRegions((prevSelected) => {
      setNeedUpdateRegions(true);
      if (prevSelected.includes(regionId)) {
        return prevSelected.filter((id) => id !== regionId);
      } else {
        return [...prevSelected, regionId];
      }
    });
  };

  const handleToggleOpen = (regionId) => {
    setOpenRegions((prevOpen) => {
      if (prevOpen.includes(regionId)) {
        return prevOpen.filter((id) => id !== regionId);
      } else {
        return [...prevOpen, regionId];
      }
    });
  };

  const filterRegions = (regionsList) => {
    return regionsList.filter((region) =>
      region.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (region.chld && filterRegions(region.chld).length > 0)
    );
  };

  const isRegionIndeterminate = (region) => {
    if (!region.chld) return false;

    const childIds = getAllRegionIds(region.chld);
    const selectedChildIds = childIds.filter(id => selectedRegions.includes(id));
    return selectedChildIds.length > 0 && selectedChildIds.length < childIds.length;
  };

  const getAllRegionIds = (regionsList) => {
    let ids = [];
    regionsList.forEach((region) => {
      ids.push(region.id);
      if (region.chld) {
        ids = [...ids, ...getAllRegionIds(region.chld)];
      }
    });
    return ids;
  };

  const renderMenuItems = (regionsList) => {
    const filteredRegions = filterRegions(regionsList);

    return filteredRegions
      .filter(region => region.name !== "Не определено" && region.name !== "Без области")
      .map((region) => {
        const isSelected = selectedRegions.includes(region.id);
        const isIndeterminate = isRegionIndeterminate(region);

        return (
          <div key={region.id}>
            <MenuItem
              onClick={() => toggleRegion(region.id, isIndeterminate)}
              style={{ display: "flex", alignItems: "center" }}
            >
              {region.chld && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleOpen(region.id);
                  }}
                  style={{
                    background: "none",
                    cursor: "pointer",
                    color: "#007bff",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: 500,
                    width: 20,
                    height: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {openRegions.includes(region.id) ? "-" : "+"}
                </button>
              )}
              <Checkbox
                checked={isSelected}
                indeterminate={isIndeterminate}
                onChange={() => toggleRegion(region.id, isIndeterminate)}
                style={{ pointerEvents: "none", marginRight: "8px" }}
              />
              <ListItemText primary={`${region.name}`} />
            </MenuItem>
            {openRegions.includes(region.id) && region.chld && (
              <Box sx={{ paddingLeft: "20px" }}>
                {renderMenuItems(region.chld)}
              </Box>
            )}
          </div>
        );
      });
  };

  const findRegionById = (regionsList, id) => {
    for (const region of regionsList) {
      if (region.id === id) return region;
      if (region.chld) {
        const found = findRegionById(region.chld, id);
        if (found) return found;
      }
    }
    return null;
  };

  const getSelectedRegionIdsWithHierarchy = (regionsList) => {
    let selectedWithHierarchy = [];

    const traverseRegions = (regionList, level) => {
      regionList.forEach((region) => {
        if (selectedRegions.includes(region.id)) {
          selectedWithHierarchy.push(`${region.id}-${level}`);
        }
        if (region.chld) {
          traverseRegions(region.chld, level + 1);
        }
      });
    };

    traverseRegions(regionsList, 1);
    return selectedWithHierarchy;
  };

  const handleCloseListRegions = () => {
    if (!needUpdateRegions) return;
  
    const selectedRegionIdsWithHierarchy = getSelectedRegionIdsWithHierarchy(regions);
  
    handleChange("geo", selectedRegionIdsWithHierarchy.join(","), true);
    setNeedUpdateRegions(false);
  };

  const handleRemoveRegion = (regionId) => {
    setSelectedRegions((prevSelected) => prevSelected.filter(id => id !== regionId));
    setNeedUpdateRegions(true);
  };

  return (
    <Box sx={{ width: 200, padding: "10px 0", textAlign: "start" }}>
      <FormControl fullWidth size="small">
        <InputLabel sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}>
          Выбор региона
        </InputLabel>
        <Select
          multiple
          value={selectedRegions}
          renderValue={() => getSelectedRegionIdsWithHierarchy(regions).join(", ")}
          onClose={handleCloseListRegions}
          style={{ height: 32, background: "#FFFFFF", borderColor: "transparent" }}
          sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
        >
          <ListSubheader>
            <Box  
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                mb: 1,
                maxWidth: "500px",
                maxHeight: "300px",
                overflowY: "auto",
                "&::-webkit-scrollbar": { display: "none" },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none"
              }}>
              {selectedRegions.map((regionId) => {
                const region = findRegionById(regions, regionId);
                return (
                  <Chip
                    key={regionId}
                    label={region?.name}
                    onDelete={() => handleRemoveRegion(regionId)}
                    style={{ border: "2px solid #3f73f994", borderRadius: "15px", margin: "3px", color: "black" }}
                  />
                );
              })}
            </Box>
            <TextField
              size="small"
              autoFocus
              placeholder="Поиск"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {renderMenuItems(regions)}
        </Select>
      </FormControl>
    </Box>
  );
};




const InputCustomArea = ({ value, handleChange }) => {
  return (
    <input
      onChange={(e) => handleChange(e.target.value)}
      defaultValue={value}
      onDoubleClick={(e) => {
        const input = e.target;
        const fullTextArea = document.createElement("textarea");

        // Настройка textarea
        fullTextArea.value = input.value;
        fullTextArea.spellcheck = false;

        fullTextArea.style.width = "95vw";
        fullTextArea.style.fontFamily = "sans-serif";
        fullTextArea.style.height = "auto";
        fullTextArea.style.padding = "10px";
        fullTextArea.style.border = "1px solid #3f73f9";
        fullTextArea.style.borderRadius = "4px";
        fullTextArea.style.position = "fixed";
        fullTextArea.style.top = "30px";
        fullTextArea.style.left = "30px";
        fullTextArea.style.zIndex = 1000;
        fullTextArea.style.backgroundColor = "#fff";
        fullTextArea.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.2)";

        fullTextArea.onblur = () => {
          fullTextArea.remove();
          input.style.display = "inline-block";
        };

        fullTextArea.oninput = (e) => {
          handleChange(e.target.value);
          input.value = e.target.value;
        };

        document.body.appendChild(fullTextArea);
        input.style.display = "none";

        fullTextArea.focus();
      }}
      style={{
        width: '100%',
        height: '30px',
        padding: '5px',
        borderRadius: '4px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    />
  );
};





const GraphComponent = ({ report_id, dates, spec }) => {
  const [specForGraph, setSpecForGraph] = useState(Number(spec));
  const [detailForGraph, setDetailForGraph] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const chartOptionFields = ["PRG1", "PRG2", "PRG10", "PRG11", "PRG12", "PRG22", "PRG8", "PRG81", "PRG84", "PRG82"];
  const [graphFields, setGraphFields] = useState([]);

  const chartOptions = ["Показы", "Клики", "Лиды", "Бюджет", "ДРР", "Доходность", "eCPC"];
  const chartOptionsDetail = ["Общая", "Детальная"];
  

  useEffect(() => {
    if (dates.date0 && dates.date1) {
      fetch(`${API_HOST}/api/v1/admin/production-reports/graphic?_id=${report_id}&date0=${dates.date0}&date1=${dates.date1}&spec=${specForGraph}&detail=${[4, 5, 6].includes(specForGraph) ? 0 : detailForGraph}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
        },
      })
        .then((res) => res.ok && res.json())
        .then((data) => setGraphData(data));
    }
  }, [specForGraph, detailForGraph, dates, report_id]);

  useEffect(() => {
    setGraphFields([])
  }, [detailForGraph])

  const handleChangeListFields = (event) => {
    const {
      target: { value },
    } = event;
    setGraphFields(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div style={{ width: "49%" }}>
      <div style={{ display: "flex" }}>
        <Box sx={{ width: 200, padding: "10px 0", textAlign: "start" }}>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}>График</InputLabel>
            <Select
              onChange={(e) => setSpecForGraph(chartOptions.indexOf(e.target.value))}
              value={chartOptions[specForGraph]}
              style={{ height: 32, background: "#FFFFFF", borderColor: "transparent" }}
              sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
            >
              {chartOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {![4, 5, 6].includes(specForGraph) && (
          <Box sx={{ width: 200, padding: "10px 0", textAlign: "start", marginLeft: 10 }}>
            <FormControl fullWidth size="small">
              <InputLabel sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}>Детализация</InputLabel>
              <Select
                onChange={(e) => setDetailForGraph(chartOptionsDetail.indexOf(e.target.value))}
                value={chartOptionsDetail[detailForGraph]}
                style={{ height: 32, background: "#FFFFFF", borderColor: "transparent" }}
                sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
              >
                {chartOptionsDetail.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {detailForGraph === 1 && ![4, 5, 6].includes(specForGraph) && (
          <Box sx={{ width: 200, padding: "10px 0", textAlign: "start", marginLeft: 10 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Линии
              </InputLabel>
              <Select
                style={{
                  margin: "0 10px",
                  fontSize: 14,
                  height: 32,
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                placeholder="Линии"
                multiple
                value={graphFields || []}
                renderValue={(selected) => selected.join(', ')}
                onChange={handleChangeListFields}
              >
                {chartOptionFields.map((field) => (
                  <MenuItem style={{ display: "flex", gap: 10 }} key={field} value={field}>
                    <Checkbox checked={graphFields.includes(field)} />
                    <ListItemText primary={field} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </div>

      {graphData.length === 0 ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px", border: "1px dashed #5b5b5b" }}>
          <p style={{ fontSize: 18 }}>Нет данных для графика.</p>
        </div>
      ) : (
        <MultiLineChartByDay data={graphData} label={chartOptions[specForGraph]} fields={graphFields} />
      )}
    </div>
  );
};


// const testDataChart2 = [
//   {
//       "date": 1728248400,
//       "value": 10
//   }, 
//   {
//       "date": 1728334800,
//       "value": 40
//   },
//   {
//       "date": 1728421200,
//       "value": 30
//   }
// ]


// const testDataChart = [
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 6 * 24 * 60 * 60, value: 2340 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 6 * 24 * 60 * 60, value: 1500 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 5 * 24 * 60 * 60, value: 2100 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 5 * 24 * 60 * 60, value: 1300 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 4 * 24 * 60 * 60, value: 2900 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 4 * 24 * 60 * 60, value: 1700 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 3 * 24 * 60 * 60, value: 1800 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 3 * 24 * 60 * 60, value: 2200 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 2 * 24 * 60 * 60, value: 2400 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 2 * 24 * 60 * 60, value: 1600 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60, value: 2500 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60, value: 1800 },
//   { name: 'PRG1', date: Math.floor(Date.now() / 1000), value: 2600 },
//   { name: 'PRG2', date: Math.floor(Date.now() / 1000), value: 1900 },
// ];

const DatePickerTest = ({ defaultValue, value, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue || '');

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setSelectedDate(formatDatePicker(value));
    } else if (defaultValue) {
      setSelectedDate(formatDatePicker(defaultValue));
    }
  }, [value, defaultValue]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    
    if (value === undefined || value === null) {
      setSelectedDate(newDate);
    }
    
    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <div style={stylesDatePicker.datepickerContainer}>
      <input 
        type="date" 
        value={selectedDate} 
        onChange={handleDateChange} 
        style={stylesDatePicker.datepickerInput}
      />
    </div>
  );
};



const formatDatePicker = (date, istext = false) => {
  if (date === 0 || !date) return;
  const d = new Date(date * 1000);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  if (istext) return `${day}.${month}.${year}`
  return `${year}-${month}-${day}`;
};

const stylesDatePicker = {
  datepickerContainer: {
    padding: "5px 0 0 0",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  datepickerInput: {
    padding: '8px',
    fontSize: '14px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    outline: 'none',
    transition: 'border-color 0.3s ease',
    ':hover': {
      borderColor: '#888',
    },
    ':focus': {
      borderColor: '#007bff',
    },
  },
};

const formatDate = (timestamp) => {
  const date = new Date(parseInt(timestamp) * 1000);
  return date.toLocaleDateString();
};

const MultiLineChartByDay = ({ data, label, fields }) => {
  const isSingleLineData = data.every(item => item.name === undefined);

  let formattedData;

  if (isSingleLineData) {
    formattedData = data.map(item => ({
      name: label,
      date: formatDate(item.date),
      value: item.value,
    }));
  } else {
    formattedData = data.map(item => ({
      ...item,
      date: formatDate(item.date),
    }));
  }

  // Получаем уникальные названия линий
  const uniqueNames = [...new Set(formattedData.map(item => item.name))];

  // Группируем данные по названиям
  const groupedData = uniqueNames.map(name => {
    const valuesByDate = formattedData.filter(item => item.name === name);
    return {
      name,
      ...Object.fromEntries(valuesByDate.map(item => [item.date, item.value])),
    };
  });

  // Формируем финальные данные с датами и значениями
  const finalData = [];
  const uniqueDates = [...new Set(formattedData.map(item => item.date))];

  uniqueDates.forEach(date => {
    const entry = { date };
    uniqueNames.forEach(name => {
      const value = groupedData.find(item => item.name === name)[date] || 0;
      entry[name] = value;
    });
    finalData.push(entry);
  });

  const linesToDisplay = fields && fields.length > 0 ? fields : uniqueNames;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={finalData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        {linesToDisplay.map((name, index) => (
          <Line 
            key={name} 
            type="monotone" 
            dataKey={name} 
            name={name} 
            stroke={[
              "#e57373",
              "#f06292",
              "#ba68c8",
              "#64b5f6",
              "#4db6ac",
              "#81c784",
              "#ffd54f",
              "#ff8a65", 
              "#ffb74d",
              "#64ffda"
            ][index % 10]}
            strokeWidth={2}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};


const OpenCampaign = ({ report_id, arrStaff, setOpenModal }) => {
  const [filterArr, setFilter] = useState([]);
  const [filterArrSvod, setFilterSvod] = useState([]);
  const [filterArrDetailsSvod, setFilterDetailsSvod] = useState([]);
  const [filterArrDetails, setFilterDetails] = useState([]);
  const [goalsListOptions, setGoalsListOptions] = useState([]);
  // const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);
  const [listGoals, setListGoals] = useState([]);
  // const [openDetails, setOpenDetails] = useState(false);
  const [specForDetails, setSpecForDetails] = useState('PRG1');
  const [openSvod, setOpenSvod] = useState(false);
  const [svodDetails, setSvodDetails] = useState("");
  const [upd, setUpd] = useState(false);
  const debounceTimers = useRef({});

  const [searchGoals, setSearchGoals] = useState('');
  const [searchStaffAccount, setSearchStaffAccount] = useState('');
  const [searchStaffContext, setSearchStaffContext] = useState('');
  const [searchStaffTraffic, setSearchStaffTraffic] = useState('');
  // const [searchStaffLead, setSearchStaffLead] = useState('');

  const [fieldsToUpdate, setFieldsToUpdate] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const [saveFields, setSaveFields] = useState(false);
  const [graphDate, setGraphDate] = useState({
    date0: 0,
    date1: 0
  })
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useAdminUser();

  const [commentValue, setCommentValue] = useState('');

  const getMoscowMidnight = (date) => {
    const targetDate = new Date(date);

    const year = targetDate.getUTCFullYear();
    const month = String(targetDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(targetDate.getUTCDate()).padStart(2, '0');

    const moscowMidnight = new Date(`${year}-${month}-${day}T00:00:00+03:00`);

    return Math.floor(moscowMidnight.getTime() / 1000);
  };

  const [detailsDate, setDetailsDate] = useState(getMoscowMidnight(new Date()));


  const additionalGoals = [
    {
      name: "Лиды(Calltracking)",
      goal_id: 1,
    },
    {
      name: "Сделки(Calltracking)",
      goal_id: 2,
    }
  ];

  

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const responseHead = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/head?_id=${report_id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (responseHead.ok) {
        const data = await responseHead.json();
        setFilter(data);
        setListGoals(data[0]?.list_of_goals.split(',').filter(Boolean));
      }
      const responseGoals = await fetch(`${API_HOST}/api/v1/admin/production-reports/get-goals?_id=${report_id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (responseGoals.ok) {
        const data = await responseGoals.json();
        setGoalsListOptions(data.concat(additionalGoals));
      }
      const responseSvod = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/summary?_id=${report_id}`, {
        method: "GET",
        headers: myHeaders,
      });
      if (responseSvod.ok) {
        const data = await responseSvod.json();
        setFilterSvod([data]);
        setCommentValue(data.comment_involtra)
      }

    }
    fetchData();
  }, [upd]);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
  
      const isToday = detailsDate === getMoscowMidnight(new Date());
  
      const url = isToday
        ? `${API_HOST}/api/v1/admin/production-reports/fetch/detalization?_id=${report_id}&spec=${specForDetails}`
        : `${API_HOST}/api/v1/admin/production-reports/fetch/detalization?_id=${report_id}&spec=${specForDetails}&date=${detailsDate}`;
  
      if (specForDetails) {
        const responseDetails = await fetch(url, {
          method: "GET",
          headers: myHeaders,
        });
  
        if (responseDetails.ok) {
          const data = await responseDetails.json();
          if (data.ok !== undefined && !data.ok && !isToday) {
            alert("Нет логов проекта за выбранную дату. Дата сброшена на сегодня.")
            setDetailsDate(getMoscowMidnight(new Date()))
          }
          setFilterDetails([data]);
        }
      }
    }
  
    fetchData();
  }, [specForDetails, upd, detailsDate]);


  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      if (svodDetails) {
        const responseDetails = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/summary-prgs?_id=${report_id}&spec=${svodDetails === "consumption" ? "budget" : svodDetails}_fact`, {
          method: "GET",
          headers: myHeaders,
        });
        if (responseDetails.ok) {
          const data = await responseDetails.json();
          setFilterDetailsSvod(
            Object.keys(data)
              .filter(key => key.startsWith('PRG'))
              .map(key => ({ prg: key, count: data[key] }))
          );
        }
      }
    }
    fetchData();
  }, [svodDetails, upd]);



  useEffect(() => {
    if (closeModal) {
      if (fieldsToUpdate.length === 0) {
        enqueueSnackbar("В отчёте не было изменений.", {
          variant: "info",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      } else {
        updateAllFields();
        enqueueSnackbar("Отчёт успешно сохранён.", {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
      setCloseModal(false);
      setOpenModal(false);
    }
  }, [closeModal]);


  useEffect(() => {
    if (saveFields) {
      updateAllFields();
      enqueueSnackbar("Отчёт успешно сохранён.", {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setSaveFields(false);
    }
  }, [saveFields]);


  const updateAllFields = async () => {
    const updatedFields = fieldsToUpdate.reduce((acc, field) => {
      acc[field.field] = field.value;
      return acc;
    }, {});
  
    await updateFields(updatedFields);
    setFieldsToUpdate([]);
    setUpd((prev) => !prev);
  };

  const debounceUpdate = (field, callback, delay = 250) => {
    clearTimeout(debounceTimers.current[field]);
    debounceTimers.current[field] = setTimeout(() => {
      callback();
      delete debounceTimers.current[field];
    }, delay);
  };
  
  const handleChange = (field, value, instant) => {
    if (instant) {
      setFieldsToUpdate((prevState) => {
        const updatedFields = prevState.map(item =>
          item.field === field ? { ...item, value } : item
        );
        return updatedFields.some(item => item.field === field)
          ? updatedFields
          : [...updatedFields, { field, value }];
      });
      return;
    }
    debounceUpdate(field, () => {
      setFieldsToUpdate((prevState) => {
        const updatedFields = prevState.map(item =>
          item.field === field ? { ...item, value } : item
        );
        return updatedFields.some(item => item.field === field)
          ? updatedFields
          : [...updatedFields, { field, value }];
      });
    });
  };

  const handleChangeDetails = (field, value, index) => {
    setFilterDetails((prevState) =>
      prevState.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  }


  const updateFields = async (fields) => {
    const fieldSuffixes = ['_consumption', '_clicks', '_views', '_leads', '_consumption_dol'];
  
    const fieldsWithDate = {};
    const fieldsWithoutDate = {};
  
    Object.entries(fields).forEach(([key, value]) => {
      if (fieldSuffixes.some(suffix => key.endsWith(suffix))) {
        fieldsWithDate[key] = value;
      } else {
        fieldsWithoutDate[key] = value;
      }
    });
  
    const isToday = detailsDate === getMoscowMidnight(new Date());
  
    if (Object.keys(fieldsWithDate).length > 0) {
      const url = isToday
        ? `${API_HOST}/api/v1/admin/production-reports/update?_id=${report_id}`
        : `${API_HOST}/api/v1/admin/production-reports/update?_id=${report_id}&date=${detailsDate}`;
      
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
        },
        body: JSON.stringify({ ...fieldsWithDate, ...fieldsWithoutDate }),
      });
    } else if (Object.keys(fieldsWithoutDate).length > 0) {
      await fetch(`${API_HOST}/api/v1/admin/production-reports/update?_id=${report_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
        },
        body: JSON.stringify(fieldsWithoutDate),
      });
    }
  };
  





  const calltrackingOptions = ["нет, но в работе", "не нужен, ecom", "да (Calltouch)", "да (Roistat)", "да (Comagic)", "да (Mango)", "да (Callibri)", "да (Битрикс24)"];
  

  const columns = [
    {
      Header: "Проект",
      accessor: "project",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
      isSticky: true,
    },
    {
      Header: "Сайт проекта",
      accessor: "domain",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
      isSticky: true,
    },
    {
      Header: "Дата начала цикла",
      accessor: "start_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Дата окончания цикла",
      accessor: "end_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Цель рк",
      accessor: "goal",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Гео",
      accessor: "geo",
      maxWidth: 400,
      minWidth: 80,
      width: 220,
    },
    {
      Header: "CallTracking",
      accessor: "calltracking",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Атрибуция по лидам",
      accessor: "attribution",
      maxWidth: 400,
      minWidth: 80,
      width: 270,
    },
    {
      Header: "Номера целей",
      accessor: "list_of_goals",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Целевые страницы",
      accessor: "landing_pages",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Название в Яметрике",
      accessor: "yametrika_name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Аккаунт-менеджер",
      accessor: "account_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 230,
    },
    // {
    //   Header: "TeamLead",
    //   accessor: "team_lead",
    //   maxWidth: 400,
    //   minWidth: 80,
    //   width: 230,
    // },
    {
      Header: "Контекстолог",
      accessor: "contextologist",
      maxWidth: 400,
      minWidth: 80,
      width: 230,
    },
    {
      Header: "Трафик-менеджер",
      accessor: "traffic_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 230,
    }
  ];


  const columnsSvod = [
    {
      Header: "КЛИКИ план",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ факт",
      accessor: "clicks_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ % цели",
      accessor: "clicks_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Вал. план",
      accessor: "leads",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Вал. факт",
      accessor: "leads_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Вал. % цели",
      accessor: "leads_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Квал. план",
      accessor: "leads_qual",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Квал. факт",
      accessor: "leads_qual_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ЛИДЫ Квал. % цели",
      accessor: "leads_qual_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "eCPL",
      accessor: "eCPL",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДОХОД план",
      accessor: "income",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДОХОД факт",
      accessor: "income_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ДРР, % факт",
      accessor: "drr_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "БЮДЖЕТ НА ПРОИЗВОДСТВО",
      accessor: "budget",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "БЮДЖЕТ факт. расход",
      accessor: "budget_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "БЮДЖЕТ % расхода планового бюджета",
      accessor: "budget_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 180,
    },
    {
      Header: "ПОКАЗЫ план",
      accessor: "views",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ПОКАЗЫ факт",
      accessor: "views_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ПОКАЗЫ % цели",
      accessor: "views_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    }
  ];

  const columnsSvodDetails = [
    {
      Header: "PRG",
      accessor: "prg",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Количество",
      accessor: "count",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ]

  function getColumnsDetails() {
    const queryName = tableDetailsOptions.find((el) => el.value === specForDetails).query;

    let columns = [
      {
        Header: "Клики",
        accessor: "clicks",
        maxWidth: 400,
        minWidth: 80,
        width: 120,
      },
      {
        Header: "Лиды",
        accessor: "leads",
        maxWidth: 400,
        minWidth: 80,
        width: 120,
      },
      {
        Header: "eCPC",
        accessor: "ecpc",
        maxWidth: 400,
        minWidth: 80,
        width: 120,
      },
    ];
  
    if (queryName === "zeropark" || queryName === "rich_ads" || queryName === "bidvert") {
      columns.unshift(
        {
          Header: "Расход (USD)",
          accessor: "consumption_dol",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        },
        {
          Header: "Расход (RUB)",
          accessor: "consumption_rub",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        }
      );
    } else {
      columns.unshift(
        {
          Header: "Расход",
          accessor: "consumption",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        },
        {
          Header: "Показы",
          accessor: "views",
          maxWidth: 400,
          minWidth: 80,
          width: 120,
        }
      );
    }
  
    return columns;
  }


  function getTableDataSvod() {

    return filterArrSvod.map((el) => {
      return {
        clicks: (
          <div className="td">
            <input onChange={(e) => handleChange('clicks', Number(e.target.value))} defaultValue={el.clicks} type="number" style={{ height: 0 }}/>
          </div>
        ),
        clicks_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.clicks_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("clicks")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        clicks_goal: (
          <div style={{ color: el.clicks_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.clicks_goal) ? el.clicks_goal : el.clicks_goal.toFixed(2)}%</div>
        ),
        leads: (
          <div className="td">
            <input onChange={(e) => handleChange('leads', Number(e.target.value))} defaultValue={el.leads} type="number" style={{ height: 0 }}/>
          </div>
        ),
        leads_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.leads_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("leads")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        leads_goal: (
          <div style={{ color: el.leads_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.leads_goal) ? el.leads_goal : el.leads_goal.toFixed(2)}%</div>
        ),
        leads_qual: (
          <div className="td">
            <input onChange={(e) => handleChange('leads_qual', Number(e.target.value))} defaultValue={el.leads_qual} type="number" style={{ height: 0 }}/>
          </div>
        ),
        leads_qual_fact: (
          <div className="td" style={{ position: "relative" }}>
            <input onChange={(e) => handleChange('leads_qual_fact', Number(e.target.value))} defaultValue={el.leads_qual_fact} type="number" style={{ height: 0 }}/>
            <button onClick={() => {setOpenSvod(true); setSvodDetails("leads_qual")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        leads_qual_goal: (
          <div style={{ color: el.leads_qual_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.leads_qual_goal) ? el.leads_qual_goal : el.leads_qual_goal.toFixed(2)}%</div>
        ),
        eCPL: (
          <div className="td">{Number.isInteger(el.eCPL) ? el.eCPL : el.eCPL.toFixed(2)}</div>
        ),
        income: (
          <div className="td">
            <input onChange={(e) => handleChange('income', Number(e.target.value))} defaultValue={el.income} type="number" style={{ height: 0 }}/>
          </div>
        ),
        income_fact: (
          <div className="td">
            <input onChange={(e) => handleChange('income_fact', Number(e.target.value))} defaultValue={el.income_fact} type="number" style={{ height: 0 }}/>
          </div>
        ),
        drr_fact: (
          <div className="td">{Number.isInteger(el.drr_fact) ? el.drr_fact : el.drr_fact.toFixed(2)}</div>
        ),
        budget: (
          <div className="td">
            <input onChange={(e) => handleChange('budget', Number(e.target.value))} defaultValue={el.budget} type="number" style={{ height: 0 }}/>
          </div>
        ),
        budget_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.budget_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("consumption")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        budget_goal: (
          <div style={{ color: el.budget_goal >= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.budget_goal) ? el.budget_goal : el.budget_goal.toFixed(2)}%</div>
        ),
        views: (
          <div className="td">
            <input onChange={(e) => handleChange('views', Number(e.target.value))} defaultValue={el.views} type="number" style={{ height: 0 }}/>
          </div>
        ),
        views_fact: (
          <div className="td" style={{ height: 30, display: "flex", alignItems: "center", gap: 5 }}>
            {el.views_fact}
            <button onClick={() => {setOpenSvod(true); setSvodDetails("views")}} style={{ padding: "3px 3px", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,104a8,8,0,0,1-16,0V59.32l-66.33,66.34a8,8,0,0,1-11.32-11.32L196.68,48H152a8,8,0,0,1,0-16h64a8,8,0,0,1,8,8Zm-40,24a8,8,0,0,0-8,8v72H48V80h72a8,8,0,0,0,0-16H48A16,16,0,0,0,32,80V208a16,16,0,0,0,16,16H176a16,16,0,0,0,16-16V136A8,8,0,0,0,184,128Z"></path></svg>
            </button>
          </div>
        ),
        views_goal: (
          <div style={{ color: el.views_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.views_goal) ? el.views_goal : el.views_goal.toFixed(2)}%</div>
        ),
      };
    });
  }


  
  function getTableDataSvodDetails() {
    return filterArrDetailsSvod.map((el) => {
      // const query = `${tableDetailsOptions.find((element) => element.value === el.prg)?.query}_${svodDetails}`
      return {
        prg: (
          <div className="td">
            {el.prg}
          </div>
        ),
        count: (
          <div className="td">
            {Number.isInteger(el.count) ? el.count : el.count.toFixed(2)}
          </div>
        )
      };
    });
  }



  const columnsLimits = [
    {
      Header: "КЛИКИ Инволтра PRG8 клик/период",
      accessor: "clicks_involtra",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ РичАдс PRG81 клик/день",
      accessor: "clicks_rich_ads",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "КЛИКИ БидВерт PRG84 клик/день",
      accessor: "clicks_bid_vert",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Средне суточное кол-во кликов",
      accessor: "daily_clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
  ];

  function getTableDataLimits() {
    return filterArrSvod.map((el) => {
      return {
        clicks_involtra: (
          <div className="td" style={{ height: 30, position: "relative" }}>
            <input 
                type="number" 
                onChange={(e) => {
                  handleChange(`clicks_involtra`, Number(e.target.value));
                }}
                defaultValue={el.clicks_involtra === 0 || el.clicks_involtra 
                  ? el.clicks_involtra
                  : ''}
            />
          </div>
        ),
        clicks_rich_ads: (
          <div className="td" style={{ height: 30 }}>
            <input 
                type="number" 
                onChange={(e) => {
                  handleChange(`clicks_rich_ads`, Number(e.target.value));
                }}
                defaultValue={el.clicks_rich_ads === 0 || el.clicks_rich_ads 
                  ? el.clicks_rich_ads
                  : ''}
            />
          </div>
        ),
        clicks_bid_vert: (
          <div className="td" style={{ height: 30 }}>
            <input 
                type="number" 
                onChange={(e) => {
                  handleChange(`clicks_bid_vert`, Number(e.target.value));
                }}
                defaultValue={el.clicks_bid_vert === 0 || el.clicks_bid_vert 
                  ? el.clicks_bid_vert
                  : ''}
            />
          </div>
        ),
        daily_clicks: (
          <div className="td">
            {el.daily_clicks != null
              ? Number.isInteger(el.daily_clicks) 
                ? el.daily_clicks 
                : el.daily_clicks.toFixed(2)
              : '0'}
          </div>
        )
      };
    });
  }

  
  function getTableDataDetails() {
    const queryName = tableDetailsOptions.find((el) => el.value === specForDetails).query;
  
    return filterArrDetails.map((el, index) => {
        const dynamicFields = {};
        if (queryName === "zeropark" || queryName === "rich_ads" || queryName === "bidvert") {
            dynamicFields.consumption_dol = (
                <div className="td" style={{ height: 30 }}>
                  <input 
                      type="number" 
                      onChange={(e) => {
                        handleChangeDetails(`${queryName}_consumption_dol`, e.target.value, index);
                        handleChange(`${queryName}_consumption_dol`, Number(e.target.value));
                      }}
                      value={el[`${queryName}_consumption_dol`] === 0 || el[`${queryName}_consumption_dol`] 
                        ? el[`${queryName}_consumption_dol`]
                        : ''}
                  />
                </div>
            );
            dynamicFields.consumption_rub = (
                <div className="td" style={{ height: 30, display: "flex", alignItems: "center" }}>
                  {Number.isInteger(el[`${queryName}_consumption_rub`]) 
                  ? el[`${queryName}_consumption_rub`] 
                  : el[`${queryName}_consumption_rub`]?.toFixed(2) || ''}
                </div>
            );
        } else {
            dynamicFields.consumption = (
                <div className="td" style={{ height: 30 }}>
                  <input 
                      type="number" 
                      onChange={(e) => {
                        handleChangeDetails(`${queryName}_consumption`, e.target.value, index);
                        handleChange(`${queryName}_consumption`, Number(e.target.value));
                      }}
                      value={el[`${queryName}_consumption`] === 0 || el[`${queryName}_consumption`] 
                        ? el[`${queryName}_consumption`]
                        : ''}
                  />
                </div>
            );
            dynamicFields.views = (
                <div className="td" style={{ height: 30 }}>
                  <input 
                      type="number" 
                      onChange={(e) => {
                        handleChangeDetails(`${queryName}_views`, e.target.value, index);
                        handleChange(`${queryName}_views`, Number(e.target.value));
                      }}
                      value={el[`${queryName}_views`] === 0 || el[`${queryName}_views`] 
                        ? el[`${queryName}_views`]
                        : ''}
                  />
                </div>
            );
        }
        
        return {
            ...dynamicFields,
            clicks: (
                <div className="td" style={{ height: 30, display: "flex", alignItems: "center" }}>
                  <input 
                    type="number" 
                    onChange={(e) => {
                      handleChangeDetails(`${queryName}_clicks`, e.target.value, index);
                      handleChange(`${queryName}_clicks`, Number(e.target.value));
                    }}
                    value={el[`${queryName}_clicks`] === 0 || el[`${queryName}_clicks`] 
                      ? el[`${queryName}_clicks`]
                      : ''}
                  />
                </div>
            ),
            leads: (
                <div className="td" style={{ height: 30 }}>
                  <input 
                      type="number" 
                      onChange={(e) => {
                        handleChangeDetails(`${queryName}_leads`, e.target.value, index);
                        handleChange(`${queryName}_leads`, Number(e.target.value));
                      }}
                      value={el[`${queryName}_leads`] === 0 || el[`${queryName}_leads`] 
                        ? el[`${queryName}_leads`]
                        : ''}
                  />
                </div>
            ),
            ecpc: (
                <div className="td" style={{ height: 30, display: "flex", alignItems: "center" }}>
                    {Number.isInteger(el[`${queryName}_ecpc`]) 
                      ? el[`${queryName}_ecpc`] 
                      : el[`${queryName}_ecpc`]?.toFixed(2) || ''}
                </div>
            ),
        };
    });
}


  const filteredGoals = goalsListOptions.filter((goal) =>
    goal.name.toLowerCase().includes(searchGoals.toLowerCase())
  );

  const filteredStaffAccount = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaffAccount.toLowerCase())
  );

  const filteredStaffContext = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaffContext.toLowerCase())
  );

  const filteredStaffTraffic = arrStaff.filter((employee) =>
    employee.full_name?.toLowerCase().includes(searchStaffTraffic.toLowerCase())
  );


  const goalsOptions = ["Лидогенерация", "Охват", "ДРР"]
  const attributionsOptions = ["Автоматическая атрибуция", "Последний значимый источник", "Последний переход из Директа", "Последний источник", "Последний значимый источник (Кросс-девайс)", "Последний переход из Директа (Кросс-девайс)", "Последний источник (Кросс-девайс)", "Первый источник трафика", "Первый источник трафика (Кросс-девайс)", "Один из источников трафика"]
  
  const handleCloseListGoals = (changesListGoals) => {
    const filteredGoals = changesListGoals.filter(goal => goal);
    handleChange('list_of_goals', filteredGoals.join(','), true);
  }

  function getTableData() {

    return filterArr.map((el) => {
      return {
        project: (
          <div className="td" style={{ height: 30 }}>
            <InputCustomArea value={el.project} handleChange={(value) => handleChange('project', value)} />
          </div>
        ),
        domain: <div className="td">{el.domain}</div>,
        goal: (
          <Box sx={{ width: 200, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Цель РК
              </InputLabel>
              <Select
                defaultValue={el.goal || "0"}
                onChange={(e) => handleChange('goal', e.target.value, true)}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="Цель РК"
                id="demo-select-small"
              >
                <MenuItem value="0">Нет</MenuItem>
                {goalsOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        calltracking: (
          <Box sx={{ width: 200, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                CallTracking
              </InputLabel>
              <Select
                onChange={(e) => handleChange('calltracking', e.target.value, true)}
                defaultValue={el.calltracking || "нет, не планируют"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
              >
                <MenuItem value="нет, не планируют">нет, не планируют</MenuItem>
                {calltrackingOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        attribution: (
          <Box sx={{ width: 240, textAlign: "start", padding: "2px 0", height: 30 }}>
            <FormControl fullWidth size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
              >
                Атрибуция по лидам
              </InputLabel>
              <Select
                onChange={(e) => handleChange('attribution', e.target.value, true)}
                defaultValue={el.attribution || "Нет"}
                style={{
                  height: 32,
                  margin: "0 10px",
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                sx={{
                  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                label="CallTracking"
                id="demo-select-small"
              >
                <MenuItem value="Нет">Нет</MenuItem>
                {attributionsOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ),
        list_of_goals: (
          <ListOfGoals goalsListOptions={goalsListOptions} filteredGoals={filteredGoals} setSearchGoals={setSearchGoals} setSearchStaffAccount={setSearchStaffAccount} handleCloseListGoals={handleCloseListGoals} defaultGoals={listGoals} />
        ),
        geo: <RegionSelect defaultValue={el.geo} regions={regionsData} handleChange={handleChange} />,
        landing_pages: <div className="td" style={{ height: 30 }}>
          <InputCustomArea value={el.landing_pages} handleChange={(value) => handleChange('landing_pages', value)} />
        </div>,
        yametrika_name: <div className="td" style={{ height: 30 }}>
          <InputCustomArea value={el.yametrika_name} handleChange={(value) => handleChange('yametrika_name', value)} />
        </div>,
        account_manager: 
          ["ADMIN", "SUPERUSER"].some((el) => el === role) ? (
            <Box sx={{ width: 220, textAlign: "start", padding: "2px 0", height: 30 }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="demo-select-small-label"
                  sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
                >
                  Аккаунт менеджер
                </InputLabel>
                <Select
                  onOpen={() => setTimeout(() => document.getElementById("search-input-account").focus(), 100)}
                  onChange={(e) => handleChange('account_manager', e.target.value === "Нет" ? null : e.target.value, true)}
                  defaultValue={el.account_manager || "Нет"}
                  style={{
                    height: 32,
                    margin: "0 10px",
                    background: "#FFFFFF",
                    borderColor: "transparent",
                  }}
                  sx={{
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  label="CallTracking"
                  id="demo-select-small"
                  onClose={() => setTimeout(() => setSearchStaffAccount(""), 300)}
                >
                  <ListSubheader>
                    <TextField
                      id="search-input-account"
                      size="small"
                      autoFocus
                      placeholder="Поиск"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchStaffAccount(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setTimeout(() => document.getElementById("search-input-account").focus(), 100)
                        }
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  <MenuItem value="Нет">Нет</MenuItem>
                  {filteredStaffAccount.map((staff) => (
                    <MenuItem key={staff.id} value={staff.id}>
                      {staff.full_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            arrStaff.find((staff) => staff.id === el.account_manager)?.full_name || "Нет"
          ),
        contextologist: 
          ["ADMIN", "SUPERUSER"].some((el) => el === role) ? (
            <Box sx={{ width: 220, textAlign: "start", padding: "2px 0", height: 30 }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="demo-select-small-label"
                  sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
                >
                  Контекстолог
                </InputLabel>
                <Select
                  onOpen={() => setTimeout(() => document.getElementById("search-input-contextologist").focus(), 100)}
                  onChange={(e) => handleChange('contextologist', e.target.value === "Нет" ? null : e.target.value, true)}
                  defaultValue={el.contextologist || "Нет"}
                  style={{
                    height: 32,
                    margin: "0 10px",
                    background: "#FFFFFF",
                    borderColor: "transparent",
                  }}
                  sx={{
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  label="CallTracking"
                  id="demo-select-small"
                  onClose={() => setTimeout(() => setSearchStaffContext(""), 300)}
                >
                  <ListSubheader>
                    <TextField
                      id="search-input-contextologist"
                      size="small"
                      autoFocus
                      placeholder="Поиск"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchStaffContext(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setTimeout(() => document.getElementById("search-input-contextologist").focus(), 100)
                        }
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  <MenuItem value="Нет">Нет</MenuItem>
                  {filteredStaffContext.map((staff) => (
                    <MenuItem key={staff.id} value={staff.id}>
                      {staff.full_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            arrStaff.find((staff) => staff.id === el.contextologist)?.full_name || "Нет"
          ),
        traffic_manager: 
          ["ADMIN", "SUPERUSER"].some((el) => el === role) ? (
            <Box sx={{ width: 220, textAlign: "start", padding: "2px 0", height: 30 }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="demo-select-small-label"
                  sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
                >
                  Трафик менеджер
                </InputLabel>
                <Select
                  onOpen={() => setTimeout(() => document.getElementById("search-input-traffic").focus(), 100)}
                  onChange={(e) => handleChange('traffic_manager', e.target.value === "Нет" ? null : e.target.value, true)}
                  defaultValue={el.traffic_manager || "Нет"}
                  style={{
                    height: 32,
                    margin: "0 10px",
                    background: "#FFFFFF",
                    borderColor: "transparent",
                  }}
                  sx={{
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  label="CallTracking"
                  id="demo-select-small"
                  onClose={() => setTimeout(() => setSearchStaffTraffic(""), 300)}
                >
                  <ListSubheader>
                    <TextField
                      id="search-input-traffic"
                      size="small"
                      autoFocus
                      placeholder="Поиск"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchStaffTraffic(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setTimeout(() => document.getElementById("search-input-traffic").focus(), 100)
                        }
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  <MenuItem value="Нет">Нет</MenuItem>
                  {filteredStaffTraffic.map((staff) => (
                    <MenuItem key={staff.id} value={staff.id}>
                      {staff.full_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            arrStaff.find((staff) => staff.id === el.traffic_manager)?.full_name || "Нет"
          ),
        start_cycle_date: <div className="td" style={{ height: 40 }}>
          <DatePickerTest defaultValue={el.start_cycle_date} onChange={(date) => handleChange('start_cycle_date', Math.floor(new Date(date).getTime() / 1000), true)} />
        </div>,
        end_cycle_date: <div className="td" style={{ height: 40 }}>
          <DatePickerTest defaultValue={el.end_cycle_date} onChange={(date) => handleChange('end_cycle_date', Math.floor(new Date(date).getTime() / 1000), true)} />
        </div>,
      };
    });
  }


  

  const tableDetailsOptions = [
    {
      name: "Я.ПОИСК PRG1",
      value: "PRG1",
      query: "yasearch"
    },
    {
      name: "РСЯ PRG2",
      value: "PRG2",
      query: "rsy"
    },
    {
      name: "ЯНДЕКС РЕТАРГЕТ PRG10",
      value: "PRG10",
      query: "yaretarget"
    },
    {
      name: "ЯНДЕКС ДПО PRG11",
      value: "PRG11",
      query: "yadpo"
    },
    {
      name: "ТОВАРКА ИЛИ МК PRG12",
      value: "PRG12",
      query: "tov_or_mk"
    },
    {
      name: "ЯНДЕКС СМАРТ PRG22",
      value: "PRG22",
      query: "yasmart"
    },
    {
      name: "ИНВОЛТРА PRG8",
      value: "PRG8",
      query: "involtra"
    },
    {
      name: "RICHADS ₽ PRG81",
      value: "PRG81",
      query: "rich_ads"
    },
    {
      name: "БИДВЕРТ ₽ PRG84",
      value: "PRG84",
      query: "bidvert"
    },
    {
      name: "ZEROPARK ₽ PRG82",
      value: "PRG82",
      query: "zeropark"
    },
  ]

  const handleDateChange = (value) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
  
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
  
    setGraphDate(() => ({
      date0: Math.floor(startDate.getTime() / 1000),
      date1: Math.floor(endDate.getTime() / 1000),
    }));
  };

  // const handleWheel = (e) => {
  //   e.preventDefault();
  //   const scrollAmount = e.deltaY * 0.8;
  //   e.currentTarget.scrollLeft += scrollAmount;
  // };

  const svodDetailsKeys = {
    leads_qual: "Лиды Квал. факт",
    clicks: "КЛИКИ факт",
    leads: "ЛИДЫ Вал. факт",
    consumption: "БЮДЖЕТ факт. расход",
    views: "ПОКАЗЫ факт",
  }

  const handleDetailsDateChange = (value) => {
    const selectedDate = new Date(value);
    const currentDate = new Date();

    console.debug(selectedDate, currentDate);
    const fieldSuffixes = ['_consumption', '_clicks', '_views', '_leads', '_consumption_dol'];
  
    if (selectedDate > currentDate) {
      enqueueSnackbar("Нельзя выбрать дату больше сегодняшней.", {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      return;
    }
  
    const hasFieldsToUpdate = fieldsToUpdate.some(fieldObj =>
      fieldSuffixes.some(suffix => fieldObj.field.endsWith(suffix))
    );
  
    if (hasFieldsToUpdate) {
      const confirmChange = window.confirm(
        "У вас есть несохраненные изменения за текущий день. Вы уверены, что хотите сменить дату и потерять все изменения?"
      );
  
      if (confirmChange) {
        const updatedFields = fieldsToUpdate.filter(fieldObj =>
          !fieldSuffixes.some(suffix => fieldObj.field.endsWith(suffix))
        );
        setFieldsToUpdate(updatedFields);
      } else {
        return;
      }
    }
    
    setDetailsDate(getMoscowMidnight(value));
  };

  return (
    <div>
      <button onClick={() => setSaveFields(true)} style={{ backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", height: 35, borderRadius: 5, position: "absolute", right: 190, top: 30 }}>Сохранить</button>
      <button onClick={() => setCloseModal(true)} style={{ backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", height: 35, borderRadius: 5, position: "absolute", right: 30, top: 30 }}>Сохранить и выйти</button>

      <div style={{ padding: 10, border: "1px solid #E0E0E0", borderRadius: 10, marginBottom: "20px"}}>
        <div style={{ width: "100%", overflowX: "auto", scrollbarWidth: "none" }}>
          <DataTable data={getTableData()} columns={columns} />
        </div>
      </div>

      <h2 style={{ marginBottom: "10px" }}>Свод</h2>
      <div style={{ padding: 10, border: "1px solid #E0E0E0", borderRadius: 10, marginBottom: "30px"}}>
        <div style={{ width: "100%", overflowX: "auto", scrollbarWidth: "none" }}>
          <DataTable data={getTableDataSvod()} columns={columnsSvod} />
        </div>
        {
          openSvod && (
            <div style={{ margin: "20px 0", paddingLeft: 25 }}>

              <div style={{ display: "flex", gap: "50px", marginBottom: "10px" }}>
                <h2 style={{ marginBottom: "10px", fontSize: 16 }}>{svodDetailsKeys[svodDetails]}</h2>
                <button onClick={() => setOpenSvod(false)} style={{ backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "3px 15px", height: 25, fontSize: 14, borderRadius: 5 }}>Закрыть детализацию</button>
              </div>

              <DataTable data={getTableDataSvodDetails()} columns={columnsSvodDetails} />
            </div>
          )
        }
      </div>

      <div style={{ display: "flex", gap: 40}}>
        <div style={{ width: "fit-content" }}>
          <div>
            <div style={{ display: "flex", gap: "16px", marginBottom: "10px", alignItems: "center" }}>
              <h2>Детализация</h2>
              <Box sx={{ width: "fit-content", textAlign: "start", paddingTop: "6px" }}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
                  >
                    Таблица
                  </InputLabel>
                  <Select
                    defaultValue={"PRG1"}
                    onChange={(e) => {
                      setSpecForDetails(e.target.value);
                    }}
                    style={{
                      height: 32,
                      background: "#FFFFFF",
                      borderColor: "transparent",
                    }}
                    sx={{
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                    }}
                    label="CallTracking"
                    id="demo-select-small"
                  >
                    {tableDetailsOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <div style={{ width: "fit-content", display: "flex", gap: "20px" }}>
                <h3 style={{ paddingTop: "11px" }}>за день:</h3>
                <div style={{ width: "150px" }}>
                  <DatePickerTest value={detailsDate} onChange={(date) => handleDetailsDateChange(date)} />
                </div>
              </div>
            </div>
            <div style={{ padding: 10, border: "1px solid #E0E0E0", borderRadius: 10, marginBottom: "20px" }}>
              <div style={{ width: "100%", overflowX: "auto", scrollbarWidth: "none", '-ms-overflow-style': 'none' }}>
                <DataTable data={getTableDataDetails()} columns={getColumnsDetails()} />
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "fit-content", marginBottom: "10px"}}>
          <div style={{ display: "flex", gap: "16px", marginBottom: "10px", alignItems: "center", paddingTop: "6px" }}>
            <h2>Лимиты</h2>
          </div>
          <div style={{ padding: 10, border: "1px solid #E0E0E0", borderRadius: 10, marginBottom: "10px" }}>
            <div style={{ width: "100%", overflowX: "auto", scrollbarWidth: "none", '-ms-overflow-style': 'none' }}>
              <DataTable data={getTableDataLimits()} columns={columnsLimits} />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <TextField
              multiline
              size="small"
              maxRows={3}
              placeholder="Комментарий"
              style={{ width: "100%" }}
              value={commentValue}
              onChange={(e) => {
                setCommentValue(e.target.value);
                handleChange("comment_involtra", e.target.value);
              }}
            />
          </div>
        </div>
      </div>



      <div style={{ marginBottom: "20px", marginTop: "30px" }}>
        <div style={{ display: "flex", gap: "25px" }}>
          <h2 style={{ marginBottom: "20px" }}>Графики</h2>
          <div style={{ width: "500px" }}>
            <DateFromTo
              from={new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000).getTime()}
              to={new Date(new Date().setHours(23, 59, 59, 999)).getTime()}
              handleChange={handleDateChange}
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", padding: 10, border: "1px solid #E0E0E0", borderRadius: 10, marginBottom: "20px" }}>
          <GraphComponent report_id={report_id} dates={graphDate} spec={2} />  
          <GraphComponent report_id={report_id} dates={graphDate} spec={3} />  
        </div>
      </div>

      <div style={{ marginTop: "60px" }}>
        <p style={{ marginBottom: "20px", fontSize: 16, color: "#3F73F9" }}>Это тестовый блок</p>
        <div style={{ display: "flex", gap: "20px", marginBottom: "10px" }}>
          <h2 style={{ marginBottom: "20px" }}>Архив проекта за</h2>
          <div style={{ width: "500px" }}>
            <Box sx={{ width: "fit-content", textAlign: "start", padding: "2px 0", height: 30 }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="demo-select-small-label"
                  sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
                >
                  Цикл проекта
                </InputLabel>
                <Select
                  defaultValue={"20.09.2024 - 01.10.2024"}
                  // onChange={(e) => handleChange('goal', e.target.value)}
                  style={{
                    height: 32,
                    margin: "0 10px",
                    background: "#FFFFFF",
                    borderColor: "transparent",
                  }}
                  sx={{
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  label="Цикл проекта"
                  id="demo-select-small"
                >
                  {["20.09.2024 - 01.10.2024", "01.10.2024 - 01.11.2024", "01.11.2024 - 20.12.2024"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div style={{ padding: 20, border: "1px solid #E0E0E0", borderRadius: 10 }}>
          <div>
            <h2 style={{ fontSize: 18 }}>Шапка</h2>
            <div style={{ width: "100%", overflowX: "auto", paddingBottom: "20px", scrollbarWidth: "none" }}>
              <DataTable data={getTableData()} columns={columns} />
            </div>
          </div>
          <div>
            <h2 style={{ fontSize: 18 }}>Свод</h2>
            <div style={{ width: "100%", overflowX: "auto", paddingBottom: "20px", scrollbarWidth: "none" }}>
              <DataTable data={getTableDataSvod()} columns={columnsSvod} />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}



const OpenModal = ({ openModal, setOpenModal, type, report_id, arrStaff, updateField, modalTableElement, field, label }) => {
  let modalContent;

  const ModalStats = ({ openModal, setOpenModal, children }) => {
    const closeModal = () => setOpenModal(false);
  
    const modalStyles = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: openModal ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    };
  
    const modalContentStyles = {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };
  
    return (
      <div style={modalStyles} onClick={closeModal}>
        <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  };

  switch (type) {
    case "detail":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={campaignStyles}>
            <div  style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: 10 }}>
              <h2>
                Шапка проекта
              </h2>
            </div>

            {report_id && <OpenCampaign report_id={report_id} arrStaff={arrStaff} setOpenModal={setOpenModal} />}
          </Box>
        </ModalStats>
      );
      break;

    case "staff":
      modalContent = (
        <ModalStats
          openModal={openModal}
          setOpenModal={() => setOpenModal(false)}
        >
          <Box sx={staffStyles}>
            <div style={{ display: "flex", width: "100%", gap: 20, flexDirection: "column" }}>
              <h3 style={{ fontSize: 18 }}>
                Проект - {modalTableElement?.project}
              </h3>
              <StaffSelect el={modalTableElement} field={field} label={label} arrStaff={arrStaff} updateField={updateField} />
            </div>
          </Box>
        </ModalStats>
      );
      break;

    default:
      modalContent = null;
  }

  return modalContent;
};

function CampaignsReports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [arrStaff, setStaff] = useState([]);
  const [filterArr, setFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTableElement, setModalTableElement] = useState({});
  const [report_id, setReport_id] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchValue, setSearchValue] = useState({
    project: '',
    domain: '',
    manager: ''
  });
  const [upd, setUpd] = useState(false);
  const { link } = useYaRequest();
  const { role } = useAdminUser();

  const [fieldAndLabel, setFieldAndLabel] = useState({
    field: '',
    label: ''
  })

  useEffect(() => {
    if (link !== null && report_id !== null && redirect) {
      console.log(link)
      const url = new URL(link);
      url.searchParams.set("redirect_uri", "https://lk.programmatic.ru/admin/campaigns-reports?report_id=" + report_id);
      // url.searchParams.set("redirect_uri", "http://localhost:3000/admin/campaigns-reports?report_id=" + report_id);
      const updatedLink = url.toString()
      window.location.href = updatedLink
    }
  }, [report_id])

  useEffect(() => {
    const codeParam = searchParams.get('code');
    const codeReportId = searchParams.get('report_id');  

    if (codeParam !== null && codeReportId !== null) {
      const params = { "code": codeParam, "_id": codeReportId.toString() };
      console.log("params: ", params);
      // const myHeaders = new Headers();
      // myHeaders.append('Content-Type', 'application/json');
      // myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem("admin_token"));
      // fetch(`${API_HOST}/api/v1/production-reports/access-code`, {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: JSON.stringify(params),
      // }).then(async response => {
      //   if (!response.ok) {
      //     alert("Ошибка подключения Яндекс Метрики!");
      //   }
      // });
      // searchParams.delete('code');
      // setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (openModal) return;
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/production-reports/fetch/head`, {
        method: "GET",
        headers: myHeaders,
      });
      if (res.ok) {
        const data = await res.json();
        setFilter(data);
      }
    }
    fetchData();
  }, [openModal, upd]);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/employee`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({}),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setStaff(data);
        });
      }
    });
  }, []);

  function getFilteredData() {
    return filterArr.filter((el) => {
      const projectMatches = searchValue.project
        ? el.project?.toLowerCase().includes(searchValue.project.toLowerCase())
        : true;
      const domainMatches = searchValue.domain
        ? el.domain?.toLowerCase().includes(searchValue.domain.toLowerCase())
        : true;
      const managerMatches = searchValue.manager
        ? [
            el.account_manager,
            el.contextologist,
            el.traffic_manager
          ].some(managerId => {
            const staffMember = arrStaff.find(staff => staff.id === managerId);
            return staffMember && staffMember.full_name.toLowerCase().includes(searchValue.manager.toLowerCase());
          })
        : true;
      return projectMatches && domainMatches && managerMatches;
    });
  }

  const updateField = async (report_id, key, value) => {
    await fetch(`${API_HOST}/api/v1/admin/production-reports/update?_id=${report_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("admin_token")}`,
      },
      body: JSON.stringify({ [key]: value }),
    });
  };




  const columns = [
    {
      Header: "Открыть",
      accessor: "open",
      maxWidth: 400,
      minWidth: 80,
      width: 90,
      isSticky: true,
    },
    {
      Header: "Проект",
      accessor: "project",
      maxWidth: 400,
      minWidth: 80,
      width: 290,
      isSticky: true,
    },
    {
      Header: "Дата начала цикла",
      accessor: "start_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 110,
    },
    {
      Header: "Дата окончания цикла",
      accessor: "end_cycle_date",
      maxWidth: 400,
      minWidth: 80,
      width: 110,
    },
    {
      Header: "КЛИКИ план",
      accessor: "clicks",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "КЛИКИ факт",
      accessor: "clicks_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "КЛИКИ % цели",
      accessor: "clicks_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ЛИДЫ Вал. план",
      accessor: "leads",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ЛИДЫ Вал. факт",
      accessor: "leads_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ЛИДЫ Вал. % цели",
      accessor: "leads_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ЛИДЫ Квал. план",
      accessor: "leads_qual",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ЛИДЫ Квал. факт",
      accessor: "leads_qual_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ЛИДЫ Квал. % цели",
      accessor: "leads_qual_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ДОХОД план",
      accessor: "income",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ДОХОД факт",
      accessor: "income_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "ДРР, % факт",
      accessor: "drr_fact",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "БЮДЖЕТ % расхода планового бюджета",
      accessor: "budget_goal",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "Аккаунт-менеджер",
      accessor: "account_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Контекстолог",
      accessor: "contextologist",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Трафик-менеджер",
      accessor: "traffic_manager",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Удалить проект",
      accessor: "delete",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Дублировать проект",
      accessor: "dublicate",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Я. Директ",
      accessor: "direct_connect",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
  ];

  const deleteProject = (project_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/production-reports/delete?_id=${project_id}`, {
      method: "POST",
      headers: myHeaders,
    }).then((res) => {
      if (res.ok) {
        res.json().then(() => {
          setUpd((prev) => !prev);
        });
      }
    });
  }

  const dublicateProject = (project_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/production-reports/dublicate?_id=${project_id}`, {
      method: "POST",
      headers: myHeaders
    }).then((res) => {
      if (res.ok) {
        res.json().then(() => {
          setUpd((prev) => !prev);
        });
      }
    });
  }

  const getLimits2Email = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("admin_token"));
  
    try {
      await fetch(`${API_HOST}/api/v1/admin/production-reports/send-traffic-report2email`, {
        method: "POST",
        headers: myHeaders
      });
      return true;
    } catch {
      return false;
    }
  };

  function getTableData() {
    return getFilteredData().map((el) => {
      return {
        project: <div className="td">{el.project}</div>,
        domain: <div className="td">{el.domain}</div>,
        start_cycle_date: <div className="td">{formatDatePicker(el.start_cycle_date, true)}</div>,
        end_cycle_date: (
          <div 
            style={{
              color:
                el.end_cycle_date * 1000 <= new Date().getTime() + 1 * 24 * 60 * 60 * 1000
                  ? 'red'
                  : el.end_cycle_date * 1000 <= new Date().getTime() + 5 * 24 * 60 * 60 * 1000
                  ? 'orange'
                  : 'black'
            }}
            className="td">
              {formatDatePicker(el.end_cycle_date, true)}
          </div>
        ),
        clicks: <div className="td">{el.clicks}</div>,
        clicks_fact: <div className="td">{Number.isInteger(el.clicks_fact) ? el.clicks_fact : el.clicks_fact.toFixed(2)}</div>,
        clicks_goal: <div style={{ color: el.clicks_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.clicks_goal) ? el.clicks_goal : el.clicks_goal.toFixed(2)}%</div>,
        leads: <div className="td">{el.leads}</div>,
        leads_fact: <div className="td">{Number.isInteger(el.leads_fact) ? el.leads_fact : el.leads_fact.toFixed(2)}</div>,
        leads_goal: <div style={{ color: el.leads_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.leads_goal) ? el.leads_goal : el.leads_goal.toFixed(2)}%</div>,
        leads_qual: <div className="td">{Number.isInteger(el.leads_qual) ? el.leads_qual : el.leads_qual.toFixed(2)}</div>,
        leads_qual_fact: <div className="td">{Number.isInteger(el.leads_qual_fact) ? el.leads_qual_fact : el.leads_qual_fact.toFixed(2)}</div>,
        leads_qual_goal: <div style={{ color: el.leads_qual_goal <= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.leads_qual_goal) ? el.leads_qual_goal : el.leads_qual_goal.toFixed(2)}%</div>,
        income: <div className="td">{Number.isInteger(el.income) ? el.income : el.income.toFixed(2)}</div>,
        income_fact: <div className="td">{Number.isInteger(el.income_fact) ? el.income_fact : el.income_fact.toFixed(2)}</div>,
        drr_fact: <div className="td">{Number.isInteger(el.drr_fact) ? el.drr_fact : el.drr_fact.toFixed(2)}</div>,
        budget_goal: <div style={{ color: el.budget_goal >= 100 ? "#ff4141" : "#49e02d" }} className="td">{Number.isInteger(el.budget_goal) ? el.budget_goal : el.budget_goal.toFixed(2)}%</div>,

        account_manager: 
          <div className="td">
            {arrStaff.length > 0 ? (
              arrStaff.find((staff) => staff.id === el.account_manager)?.full_name || "Нет"
            ) : (
              "Загрузка..."
            )}
            {["ADMIN", "SUPERUSER"].some((el) => el === role) && (
              <svg
                onClick={() => {
                  setReport_id(el.id)
                  setFieldAndLabel({
                    field: "account_manager",
                    label: "Аккаунт-менеджер",
                  })
                  setModalType("staff")
                  setModalTableElement(el)
                  setOpenModal(true)
                }}
                style={{ marginLeft: 10, cursor: "pointer" }}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                  fill="black"
                />
              </svg>
            )}
          </div>,
        contextologist: 
          <div className="td">
            {arrStaff.length > 0 ? (
              arrStaff.find((staff) => staff.id === el.contextologist)?.full_name || "Нет"
            ) : (
              "Загрузка..."
            )}
            {["ADMIN", "SUPERUSER"].some((el) => el === role) && (
              <svg
                onClick={() => {
                  setReport_id(el.id)
                  setFieldAndLabel({
                    field: "contextologist",
                    label: "Контекстолог",
                  })
                  setModalType("staff")
                  setModalTableElement(el)
                  setOpenModal(true)
                }}
                style={{ marginLeft: 10, cursor: "pointer" }}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                  fill="black"
                />
              </svg>
            )}
          </div>,
        traffic_manager: 
          <div className="td">
            {arrStaff.length > 0 ? (
              arrStaff.find((staff) => staff.id === el.traffic_manager)?.full_name || "Нет"
            ) : (
              "Загрузка..."
            )}
            {["ADMIN", "SUPERUSER"].some((el) => el === role) && (
              <svg
                onClick={() => {
                  setReport_id(el.id)
                  setFieldAndLabel({
                    field: "traffic_manager",
                    label: "Трафик менеджер",
                  })
                  setModalType("staff")
                  setModalTableElement(el)
                  setOpenModal(true)
                }}
                style={{ marginLeft: 10, cursor: "pointer" }}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                  fill="black"
                />
              </svg>
            )}
          </div>,
        open: (
          <button
            onClick={() => {
              setReport_id(el.id)
              setOpenModal(true)
              setModalType("detail")
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Открыть 
          </button>
        ),
        delete: (
          <button
            onClick={() => {
              confirm("Вы уверены что хотите удалить проект - " + el.project + " ?") && deleteProject(el.id)
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Удалить 
          </button>
        ),
        dublicate: (
          <button
            onClick={() => {
              confirm("Вы уверены что хотите создать дубликат проекта - " + el.project + " ?") && dublicateProject(el.id)
            }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
            Дублировать
          </button>
        ),
        direct_connect: (
          <button
          disabled={searchParams.get('report_id') === el.id ? true : false}
          onClick={() => {
            setReport_id(el.id)
            setRedirect(true)
           }}
            style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: searchParams.get('report_id') === el.id ? "gray" : "#DFE8FF", color: searchParams.get('report_id') === el.id ? "white" : "#3F73F9" }}>
            {searchParams.get('report_id') === el.id ? "Подключено - Test" : "Подключить"}
          </button>
        ),
      };
    });
  }
  return (
    <div className="logs" style={{ width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <div className="filters">
          <input
            onChange={(e) =>
              setSearchValue((prev) => ({ ...prev, project: e.target.value }))
            }
            className="searchEmploee"
            placeholder="По названию проекта"
          />
          <input
            onChange={(e) =>
              setSearchValue((prev) => ({ ...prev, domain: e.target.value }))
            }
            className="searchEmploee"
            placeholder="По сайту проекта"
          />
          <input
            onChange={(e) =>
              setSearchValue((prev) => ({ ...prev, manager: e.target.value }))
            }
            className="searchEmploee"
            placeholder="По сотруднику"
          />
        </div>
        <button
          onClick={async () => {
            const result = await getLimits2Email();
            if (result) {
              enqueueSnackbar("Отчёт по лимитам отправлен вам на почту.", {
                variant: "info",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            } else {
              enqueueSnackbar("Не получилось отправить отчёт по лимитам.", {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
          }}
          style={{ marginTop: 25, width: "fit-content", backgroundColor: "#dfe8ff", color: "#3f73f9", padding: "5px 10px", height: 35, fontSize: 14, borderRadius: 5 }}>
          Получить отчёт по лимитам
        </button>
      </div>

      <div style={{ overflowX: "auto", width: "100%" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <OpenModal openModal={openModal} setOpenModal={setOpenModal} type={modalType} report_id={report_id} arrStaff={arrStaff} updateField={updateField} modalTableElement={modalTableElement} field={fieldAndLabel.field} label={fieldAndLabel.label}/>
    </div>
  );
}

export default CampaignsReports;
